import {patchObjectsByObjectId, SUB_EVENT_TYPES} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { inject, provide } from 'vue-demi';
import objectGroupSchema from '../api/object-groups.graphql';
import { promisifyQuery } from '@/utils';
import {checkEntityBySchemaTags, EntityEnum} from "@/provider/entities";

export function createObjectGroupsStore() {
  const { result, load, subscribeToMore, onError, onResult } = useLazyQuery(
    objectGroupSchema.fetchObjectGroups,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const objectGroupList = useResult(result, [], data => data.objects);

  subscribeToMore({
    document: objectGroupSchema.listenObjectGroupList,
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('object group subscription', subscriptionData);
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Object':
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        case 'ObjectsToObject': {
          if (
              checkEntityBySchemaTags(
                  EntityEnum.Object,
                  relatedNode.object2?.schemaTags || []
              )
          ) {
            // patch current objects
            const patchedObjects = patchObjectsByObjectId(
                relatedNode.object1Id,
                'objectsWithinGroup',
                relatedNode,
                previousResult.objects
            );

            if (patchedObjects) {
              return {
                objects: patchedObjects
              };
            }
          }
        }
      }
    }
  });

  onError(console.error);

  const groupById = id => objectGroupList.value.find(i => i.id === id);

  return {
    groupById,
    objectGroupList,
    load: promisifyQuery(load, onResult, onError)
  };
}

export const ObjectGroupsProviderSymbol = Symbol('Object groups identifier');

export const useObjectGroupsProvider = () => {
  provide(ObjectGroupsProviderSymbol, createObjectGroupsStore());
};

export function useObjectGroups() {
  return inject(ObjectGroupsProviderSymbol);
}
